var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login__wrap"},[_c('img',{staticClass:"login__logo",attrs:{"src":require("assets/images/logo.svg"),"alt":"логотип 05.ru"}}),_vm._v(" "),_c('div',{staticClass:"login__form"},[_c('ActionEntity',{attrs:{"actions":{ send: _vm.$api.auth.login }},on:{"success":_vm.onSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var send = ref.send;
var loading = ref.loading;
var error = ref.error;
var success = ref.success;
var response = ref.response;
return [_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('Form',{attrs:{"type":"vertical"},on:{"submit":function($event){handleSubmit(function () { return send(_vm.token); })}}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"пароль"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('FormElement',{attrs:{"title":"Пароль:","error":errors[0]}},[_c('InputText',{attrs:{"type":"password","autocomplete":"current-password"},on:{"input":validate},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}})],1)]}}],null,true)}),_vm._v(" "),(error)?_c('Note',{staticClass:"mb-20 p",attrs:{"theme":"reddish"}},[_vm._v(_vm._s(response))]):_vm._e(),_vm._v(" "),(success)?_c('Button',{attrs:{"theme":"submited","icon":"tick","disabled":"","fluid":""}},[_vm._v("\n              Вход совершен\n            ")]):(loading)?_c('Button',{attrs:{"loading":"","fluid":""}}):_c('Button',{attrs:{"fluid":""}},[_vm._v("Войти")])],1)]}}],null,true)})]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }