//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { InputText, Button, Form, FormElement, Note } from '05-ui-kit'

export default {
  layout: 'auth',
  components: {
    InputText,
    Button,
    Form,
    FormElement,
    Note,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      token: null,
    }
  },
  methods: {
    onSuccess() {
      this.$store.commit('user/setToken', this.token)
      this.$axios.setToken(this.token)
      this.$router.push('/')
    },
  },
}
